import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Row, Col, Button, Tabs, Upload } from 'antd';
import { EditorValidationBlog } from '../../validations-schema/ValidationsSchema';
import { initialItemsBlog } from '../../constants/tabItems';
import { useBlog } from './useBlog';
import styles from './Blog.module.scss';
import TrashIconSvg from '../../components/svgs/TrashIconSvg';
import { initialValuesBlog } from '../../constants/initialValues';

const Blog = () => {
  const {
    formikRef,
    blogActions,
    onPictureUpload,
    images,
    deleteImage,
    title,
    contextHolder,
    uploadErrors,
  } = useBlog();
  return (
    <div className={styles.blog}>
      {contextHolder}
      <h1>{title}</h1>
      <div className={styles.itemsWrapper}>
        <Formik
          initialValues={initialValuesBlog}
          onSubmit={(values) => blogActions(values)}
          validationSchema={EditorValidationBlog}
          innerRef={formikRef}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                <div className={styles.tabsWrapper}>
                  <Tabs defaultActiveKey="1" items={initialItemsBlog} />

                  <div className={styles.outerTabsContent}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <label className="label">Հեղինակի անուն *</label>
                        <Field type="text" name="author_name" />
                        {errors.author_name && touched.author_name && (
                          <span className="error-msg">{errors.author_name}</span>
                        )}
                      </Col>
                      <Col span={5}>
                        <Row gutter={[0, 20]}>
                          <Col span={24}>
                            <label className="label">Հեղինակի նկար</label>
                            <Upload
                              name="author_image"
                              listType="picture-card"
                              className={`avatar-uploader ${styles.uploaderWrapper}`}
                              showUploadList={false}
                              customRequest={(file) => {
                                onPictureUpload(file.file, 'author_image');
                              }}
                            >
                              {images.author_image ? (
                                <>
                                  <img
                                    src={images.author_image}
                                    alt="avatar"
                                    style={{
                                      width: '100%',
                                    }}
                                  />
                                  <button
                                    className={styles.imageDelete}
                                    type="button"
                                    onClick={deleteImage}
                                  >
                                    <TrashIconSvg />
                                  </button>
                                </>
                              ) : (
                                <div>
                                  +
                                  <div
                                    style={{
                                      marginTop: 8,
                                    }}
                                  >
                                    Upload
                                  </div>
                                </div>
                              )}
                            </Upload>
                            {uploadErrors.author_image && (
                              <span className="error-msg">{uploadErrors.author_image}</span>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <label className="label">Բլոգի նկար *</label>
                        <Upload
                          name="image"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={(file) => {
                            onPictureUpload(file.file, 'image');
                          }}
                        >
                          {images.image ? (
                            <img
                              src={images.image}
                              alt="avatar"
                              style={{
                                width: '100%',
                              }}
                            />
                          ) : (
                            <div>
                              +
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </div>
                          )}
                        </Upload>
                        {uploadErrors.image && (
                          <span className="error-msg">{uploadErrors.image}</span>
                        )}
                        {errors.image && touched.image && (
                          <span className="error-msg">{errors.image}</span>
                        )}
                      </Col>

                      <Col span={12}>
                        <label className="label">Share նկար *</label>
                        <Upload
                          name="share_image"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={(file) => {
                            onPictureUpload(file.file, 'share_image');
                          }}
                        >
                          {images.share_image ? (
                            <img
                              src={images.share_image}
                              alt="avatar"
                              style={{
                                width: '100%',
                              }}
                            />
                          ) : (
                            <div>
                              +
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </div>
                          )}
                        </Upload>
                        {uploadErrors.share_image && errors.share_image && (
                          <span className="error-msg">{uploadErrors.share_image}</span>
                        )}
                        {errors.share_image && (
                          <span className="error-msg">{errors.share_image}</span>
                        )}
                      </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" className="submit-btn">
                      Պահպանել
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Blog;
