import React from 'react';
import CustomEditor from '../components/shared/custom-editor/CustomEditor';

export const initialItemsVacancy = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor initialNames={{ title: 'title_en', content: 'content_en', url: 'slug' }} />
    ),
    key: '1',
  },
  // {
  //   label: 'Գերմաներեն',
  //   children: (
  //     <CustomEditor
  //       tabGerman
  //       initialNames={{ title: 'title_de', content: 'content_de', url: 'slug' }}
  //     />
  //   ),
  //   key: '2',
  // },
];

export const initialItemsBlog = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor
        initialNames={{
          title: 'title_en',
          content: 'content_en',
          category: 'category_en',
          url: 'slug',
          text: 'text',
        }}
        blog
      />
    ),
    key: '1',
  },
  // {
  //   label: 'Գերմաներեն',
  //   children: (
  //     <CustomEditor
  //       tabGerman
  //       initialNames={{
  //         title: 'title_de',
  //         content: 'content_de',
  //         category: 'category_de',
  //         url: 'slug',
  //         text: 'text',
  //       }}
  //       blog
  //     />
  //   ),
  //   key: '2',
  // },
];
