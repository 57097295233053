import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../services/useServices';

export const useBlog = () => {
  const [api, contextHolder] = notification.useNotification();
  const [uploadErrors, setUploadErrors] = useState({});

  const openNotification = () => {
    api.info({
      message: 'Զգուշացում',
      description: 'Դաշտերի պարունակությունը սխալ է',
    });
  };
  const formikRef = useRef(null);
  const { id } = useParams();
  const { createRequest, editRequest, uploadImageRequest, getSingleVacanyAndBlogData } =
    useServices();
  const title = id ? 'Փոփոխել բլոգը' : 'Ավելացնել բլոգ';
  const [images, setImages] = useState({
    author_image: '',
    image: '',
    share_image: '',
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getEditableBlog = async () => {
    const data = await getSingleVacanyAndBlogData('/open-blog', id);
    if (formikRef?.current && data) {
      setImages({
        author_image: data.author_image ? `${BASE_URL}/${data.author_image}` : '',
        image: `${BASE_URL}/${data.image}`,
        share_image: `${BASE_URL}/${data.share_image}`,
      });

      data.category_en = data.category_en.join(',');
      if (data.category_de) {
        data.category_de = data.category_de.join(',');
      } else {
        data.category_de = '';
      }
      formikRef.current.setValues(data);
    }
  };
  const navigate = useNavigate();
  const deleteImage = (e) => {
    e.stopPropagation();
    formikRef.current.setFieldValue('author_image', '');
    setImages((prev) => {
      return {
        ...prev,
        author_image: '',
      };
    });
  };

  const checkEmptyBlog = (values) => {
    let checked = true;
    Object.keys(values).forEach((prop) => {
      if (typeof values[prop] === 'string') {
        const value = values[prop].replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '').trim();

        if (!value) {
          if (prop === 'content_en') {
            checked = false;
          }
        }
      }
    });
    return checked;
  };

  const blogActions = async (values) => {
    const modifiedValues = { ...values };
    if (typeof modifiedValues.image !== 'string') {
      const res = await uploadImageRequest(modifiedValues.image);
      formikRef.current.setFieldValue('image', res);
      modifiedValues.image = res;
    }

    if (typeof modifiedValues.share_image !== 'string') {
      const share_image = await uploadImageRequest(modifiedValues.share_image);
      formikRef.current.setFieldValue('share_iamge', share_image);
      modifiedValues.share_image = share_image;
    }

    if (modifiedValues.author_image && typeof modifiedValues.author_image !== 'string') {
      const author = await uploadImageRequest(modifiedValues.author_image);
      modifiedValues.author_image = author;
      formikRef.current.setFieldValue('author_image', author);
    }

    modifiedValues.category_en = modifiedValues.category_en.trim().split(',');

    modifiedValues.category_de = modifiedValues.category_de.trim()
      ? modifiedValues.category_de.trim().split(',')
      : '';
    const check = checkEmptyBlog(modifiedValues);
    if (check) {
      if (id) {
        await editRequest('blog', id, modifiedValues).then(() => {
          navigate('/blogs');
        });
      } else {
        await createRequest('/blog', modifiedValues).then(() => {
          navigate('/blogs');
        });
      }
    } else {
      openNotification();
    }
  };

  const onPictureUpload = async (file, field) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImages((prev) => ({
        ...prev,
        [field]: reader.result,
      }));
    });

    reader.readAsDataURL(file);

    try {
      const uploadedImage = await uploadImageRequest(file);
      formikRef.current.setFieldValue(field, uploadedImage);
      setUploadErrors((prev) => ({ ...prev, [field]: null }));
    } catch (error) {
      setUploadErrors((prev) => ({
        ...prev,
        [field]: 'File is too large. Please upload a smaller file.',
      }));
    }
  };

  useEffect(() => {
    if (id) {
      getEditableBlog();
    }
  }, [id]);
  return {
    formikRef,
    blogActions,
    onPictureUpload,
    images,
    deleteImage,
    title,
    contextHolder,
    uploadErrors,
  };
};
