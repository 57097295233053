import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactQuill from 'react-quill';
import { Col, Row, Modal, Upload } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './CustomEditor.module.scss';
import { useServices } from '../../../services/useServices';
import TrashIconSvg from '../../svgs/TrashIconSvg';

const CustomEditor = ({ initialNames, blog = false, tabGerman = false }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const { uploadImageRequest, BASE_URL } = useServices();
  const quillRef = useRef(null);

  const RenderModal = useMemo(() => {
    return (
      <Modal
        open
        className="deleteModal"
        cancelText="Cancel"
        okText="Add to Blog"
        title="Please upload an image"
        onOk={() => {
          if (!quillRef.current) return;

          const editor = quillRef.current.getEditor();
          editor.focus();

          const range = editor.getSelection();

          const imageUrl = `${BASE_URL}/${uploadedImage}`;

          const position = range ? range.index : editor.getLength();

          if (uploadedImage) {
            editor.insertEmbed(position, 'image', imageUrl, 'user');
          }
          setIsShowModal(false);
          setUploadedImage(null);
        }}
        onCancel={() => {
          setUploadedImage(false);
          setIsShowModal(false);
        }}
      >
        <div>
          <Upload
            name="blogImage"
            listType="picture-card"
            className={`avatar-uploader ${styles.uploaderWrapper}`}
            showUploadList={false}
            customRequest={(file) => {
              uploadImageRequest(file.file).then((res) => {
                setUploadedImage(res);
              });
            }}
          >
            {uploadedImage ? (
              <div className={styles.uploaderWrapper}>
                <img src={`${BASE_URL}/${uploadedImage}`} alt="avatar" />
                <button
                  className={styles.imageDelete}
                  type="button"
                  onClick={(e) => {
                    setUploadedImage(null);
                    e.stopPropagation();
                  }}
                >
                  <TrashIconSvg />
                </button>
              </div>
            ) : (
              <div>
                +
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            )}
          </Upload>
        </div>
      </Modal>
    );
  }, [uploadedImage, quillRef?.current]);

  const { id } = useParams();

  const imageHandler = () => {
    setIsShowModal(true);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }, 'custom'],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <div className={styles.customEditorWrapper}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div>
            <p />
            <label htmlFor={initialNames.title} className="label">
              {id
                ? `Փոփոխել Վերնագիրը ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Վերնագիր ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.title} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </div>
        </Col>
        <Col span={24}>
          <div>
            <p />
            <label htmlFor={initialNames.url} className="label">
              {id
                ? `change slug ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `slug ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.url} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </div>
        </Col>
        <Col span={24}>
          <label className="label" htmlFor={initialNames.content}>
            {id
              ? `Փոփոխել պարունակությունը ${tabGerman ? '(Գերմաներեն)' : '*'}`
              : `Պարունակություն ${tabGerman ? '(Գերմաներեն)' : '*'} `}
          </label>
          <Field name={initialNames.content}>
            {({ field: { onChange, name }, meta: { error, touched, value } }) => (
              <>
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  onChange={onChange(name)}
                  value={value}
                  modules={modules}
                />
                {error && touched && <span className="error-msg">{error}</span>}
              </>
            )}
          </Field>
        </Col>
        {blog && (
          <Col span={24}>
            <label className="label">
              {' '}
              {id
                ? `Փոփոխել կատեգորիաները ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Ավելացնել կատեգորիաներ ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.category} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </Col>
        )}
        {blog && (
          <Col span={24}>
            <label className="label">
              {' '}
              {id
                ? `Փոփոխել ներածությունը ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Ներածություն ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.text} type="text">
              {({ field: { name, onChange }, meta: { error, value } }) => {
                return (
                  <>
                    <textarea
                      name={name}
                      onChange={onChange(name)}
                      value={value}
                      className="textarea"
                    />
                    {error && <p className="error-msg">{error}</p>}
                  </>
                );
              }}
            </Field>
          </Col>
        )}
      </Row>
      {isShowModal && RenderModal}
    </div>
  );
};

export default CustomEditor;

CustomEditor.propTypes = {
  initialNames: PropTypes.object,
  blog: PropTypes.bool,
  tabGerman: PropTypes.bool,
};
